<template>
  <a-drawer
    closable
    class="draw-box"
    rootClassName="root-drawer"
    style=""
    rootStyle=""
    maskStyle=""
    bodyStyle=""
    headerStyle=""
    footerStyle=""
    contentWrapperStyle=""
    :open="open"
    :width="width"
    :title="title"
    :zIndex="zIndex"
    :placement="placement"
    :destroyOnClose="destroyOnClose"
    @close="onClose"
  >
    <slot name="closeIcon"></slot>
    <slot name="extra"></slot>
    <!-- <slot name="footer"></slot> -->
    <template #footer>
      <drawer-submit-footer @close="onClose" @ok="onOk"></drawer-submit-footer>
    </template>
    <slot></slot>
  </a-drawer>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  destroyOnClose: {
    type: Boolean,
    default: true,
  },
  placement: {
    type: String,
    default: "right",
  },
  title: {
    type: String,
    default: "",
  },
  width: {
    type: [String, Number],
    default: 500,
  },
  zIndex: {
    type: Number,
    default: 1000,
  },
});

const emit = defineEmits(["update:open", "close", "ok"]);

const onClose = () => {
  emit("close");
  emit("update:open");
};

const onOk = () => {
  emit("ok");
};
</script>
