import { useMenuStore } from "@/stores/menus";

export default {
  mounted(el, binding) {
    const store = useMenuStore();
    const permissions = store.permissions;
    const { value } = binding;
    console.log(value);
    console.log(permissions);

    const hasPermissions = value.some((key) => permissions.indexOf(key) > -1);
    console.log(hasPermissions);
    console.log(el.parentNode);
    if (!hasPermissions) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
};
