<template>
  <a-select
    :size="size"
    :value="dutyTypeId"
    style="width: 120px"
    :class="{
      [size]: size,
    }"
    @change="handleChangeType"
  >
    <a-select-option v-for="type in typeList" :key="type.id" :value="type.id">{{
      type.name
    }}</a-select-option>
  </a-select>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";
import { useMenuStore } from "@/stores/menus";
import { useRoute } from "vue-router";

import { getAllGroup } from "@/api/duty/lab-group";

import mitt from "@/utils/mitt";

const route = useRoute();

const menuStore = useMenuStore();

const permissions = menuStore.permissions;

const emit = defineEmits(["update:value", "change"]);

defineProps({
  size: {
    type: String,
    default: "default",
  },
});

const dutyType = ref(null);
const dutyTypeId = ref();
const typeList = ref([]);

const handleChangeType = (id) => {
  const type = typeList.value.find((item) => item.id === id);
  dutyTypeId.value = type.id;
  if (route.path.indexOf("duty") > -1) {
    dutyType.value = type.code;
  } else {
    dutyType.value = type.partCode;
  }
  mitt.emit("changeDutyType", dutyType.value);
  mitt.emit("changeDutyTypeObject", type);
  emit("update:value", dutyType.value);
  emit("change", dutyType.value);
};

const fetchGroupList = async () => {
  const codeList = permissions.filter((permission) =>
    route.path.indexOf("duty") > -1
      ? permission.startsWith("DUTY-TYPE")
      : permission.startsWith("VCVP-DEVICE-TYPE")
  );
  const res = await getAllGroup({
    codeList,
  });
  typeList.value = res.data;
  if (route.path.indexOf("duty") > -1) {
    dutyType.value = res.data[0].code;
  } else {
    dutyType.value = res.data[0].partCode;
  }
  dutyTypeId.value = res.data[0]?.id;
  mitt.emit(`changeDutyType`, dutyType.value);
  mitt.emit("changeDutyTypeObject", res.data[0]);
  emit("update:value", dutyType.value);
  emit("change", dutyType.value);
};

onMounted(() => {
  fetchGroupList();
});
</script>

<style lang="scss">
.default {
  .ant-select-selection-item {
    line-height: 32px !important;
  }
}
.large {
  .ant-select-selection-item {
    line-height: 40px !important;
  }
}
</style>
