<template>
  <a-input-number
    :size="size"
    :bordered="bordered"
    :controls="controls"
    :placeholder="placeholder"
    :disabled="disabled"
    :min="min"
    :max="max"
    :precision="precision"
    :step="step"
    :string-mode="stringMode"
    @change="onChange"
    @pressEnter="onPressEnter"
    @step="onStep"
  ></a-input-number>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  // 组件大小
  size: {
    type: String,
    default: "large",
  },
  // 是否显示边框
  bordered: {
    type: Boolean,
    default: false,
  },
  // 是否显示增减按钮
  controls: {
    type: Boolean,
    default: false,
  },
  // 是否禁用
  disabled: {
    type: Boolean,
    default: false,
  },
  // 最大值
  max: {
    type: Number,
    default: Infinity,
  },
  // 最小值
  min: {
    type: Number,
    default: -Infinity,
  },
  // 数值精度
  precision: {
    type: Number,
    default: 0,
  },
  // 步长
  step: {
    type: Number,
    default: 1,
  },
  // 字符值模式，开启后支持高精度小数。同时 change 事件将返回 string 类型
  stringMode: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "请输入",
  },
});

const emit = defineEmits(["change", "pressEnter", "step"]);

const onChange = (value) => {
  emit("change", value);
};

const onPressEnter = (e) => {
  emit("pressEnter", e);
};

const onStep = (value, info) => {
  emit("step", { value, info });
};
</script>
