<template>
  <a-drawer
    :open="open"
    :closable="false"
    width="300"
    :title="null"
    placement="left"
    @close="onClose"
  >
    <template #footer>
      <div style="padding-left: 8px">
        <p class="menu-name">功能菜单</p>
        <div>
          <div
            class="menu-title"
            @click="toLogs"
            v-if="permissions.includes('default_sysLog')"
          >
            <div class="img" style="background: rgb(104, 99, 251)">
              <img src="/menus/logs.svg" class="icon" />
            </div>
            系统日志
          </div>
          <div
            class="menu-title"
            @click="toSettings"
            v-if="permissions.includes('default_system')"
          >
            <div class="img" style="background: rgb(4, 183, 138)">
              <img src="/menus/settings.svg" class="icon" />
            </div>
            系统设置
          </div>
          <div
            class="menu-title"
            @click="toInfo"
            v-if="permissions.includes('default_personal')"
          >
            <div class="img" style="background: #1eb7e8">
              <img src="/menus/profile.svg" class="icon" />
            </div>
            个人中心
          </div>
        </div>
      </div>
    </template>
    <div class="home-row">
      <a-button
        size="large"
        class="home-button"
        style="width: 90px"
        @click="toHome"
      >
        <HomeFilled />
        <span>主页</span>
      </a-button>
    </div>
    <a-row :gutter="[16, 16]" style="margin-top: 50px">
      <a-col :span="12">
        <div class="menu-content" @click="toMenu('admin')">
          <img src="/menus/admin.png" />
        </div>
      </a-col>
      <a-col :span="12">
        <div class="menu-content" @click="toMenu('eag')">
          <img src="/menus/eag.png" />
        </div>
      </a-col>
      <a-col :span="12">
        <div class="menu-content" @click="toMenu('feedback')">
          <img src="/menus/feedback.png" />
        </div>
      </a-col>
      <a-col :span="12">
        <div class="menu-content" @click="toMenu('facility')">
          <img src="/menus/facility.png" />
        </div>
      </a-col>
      <a-col :span="12">
        <div class="menu-content" @click="toMenu('lab')">
          <img src="/menus/lab.png" />
        </div>
      </a-col>
    </a-row>

    <!-- <template #title>
      <a-button size="large">
        <HomeFilled />
        <span>主页</span>
      </a-button>
    </template> -->
  </a-drawer>
</template>

<script setup>
import { onMounted, defineEmits } from "vue";
import { HomeFilled } from "@ant-design/icons-vue";

import { useRouter } from "vue-router";

import { useMenuStore } from "@/stores/menus";

const emit = defineEmits("close");

const store = useMenuStore();
const permissions = store.permissions;

const router = useRouter();

const onClose = () => {
  emit("close");
};

const toMenu = (type) => {
  let url = "";
  switch (type) {
    case "admin":
      url = "/index/admin/order-food/initiate";
      break;
    case "eag":
      url = "/index/EAG/exercise-check-in/review";
      break;
    case "feedback":
      url = "/index/feedback/QA/feedback-query";
      break;
    case "facility":
      url = "/index/facility/energy/statistics";
      break;
    case "lab":
      url = "/index/vc/vp/on-duty/clean-item-settings";
      break;
    default:
      break;
  }
  window.location.href = `${url}`;
};

const toLogs = () => {
  window.location.href = `${process.env.VUE_APP_BASE_URL}/systemLog/Login`;
};

const toSettings = () => {
  window.location.href = `${process.env.VUE_APP_BASE_URL}/accountPower/AccountManage`;
};

const toInfo = () => {
  window.location.href = `${process.env.VUE_APP_BASE_URL}/personal/Index`;
};

const toHome = () => {
  if (process.env.NODE_ENV === "development") {
    router.push("/");
  } else {
    window.location.href = `${process.env.VUE_APP_BASE_URL}/index`;
  }
};

onMounted(() => {
  console.log("==============");
});
</script>

<style lang="scss" scoped>
.menu-content {
  cursor: pointer;
  border-radius: 6px;
  text-align: center;
  &:hover {
    outline: 1px solid #00a4f5 !important;
    box-shadow: 0 6px 13px 0 #d6ebff;
  }
  img {
    width: 100%;
  }
}

.home-button {
  background: #fbfdff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 1px solid #e9edf0;
  padding: 2px 5px 4px;
}

.menu-name {
  font-size: 18px;
  font-weight: 600;
  color: #344563;
}

.menu-title {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #5a6c86;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:hover {
    .img {
      opacity: 0.8;
    }
  }
  .img {
    border-radius: 6px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
