<template>
  <y-space>
    <a-button type="primary" @click="onClose">取消</a-button>
    <a-button type="primary" @click="onSubmit">提交</a-button>
  </y-space>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["close", "ok"]);

const onClose = () => {
  emit("close");
};

const onSubmit = () => {
  emit("ok");
};
</script>
