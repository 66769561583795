<template>
  <a-input
    allowClear
    autocomplete="off"
    :value="value"
    :bordered="false"
    :placeholder="placeholder"
    :maxlength="maxLength"
    @input="onInput"
  >
    <slot name="prefix"></slot>
  </a-input>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

defineProps({
  placeholder: {
    type: String,
    default: "请输入",
  },
  value: {
    type: String,
  },
  maxLength: {
    type: Number,
    default: Infinity,
  },
});

const emit = defineEmits(["update:value"]);

const onInput = (e) => {
  emit("update:value", e.target.value);
};
</script>

<style lang="scss" scoped></style>
