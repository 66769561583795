<template>
  <a-tree-select
    size="large"
    style="width: 100%"
    :allowClear="allowClear"
    :placeholder="placeholder"
    :tree-data="treeData"
    :maxTagCount="maxTagCount"
    :treeCheckable="treeCheckable"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :field-names="{
      children: 'children',
      label: 'name',
      value: 'id',
    }"
    @change="handleChange"
    @treeExpand="onLoadData"
  ></a-tree-select>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, unref } from "vue";

import { getDepartmentList, getUsersByDepartment } from "@/api/user";

import { convertTreeToList } from "@/utils/utils";

// const props =
defineProps({
  treeCheckable: {
    type: Boolean,
    default: true,
  },
  allowClear: {
    type: Boolean,
    default: false,
  },
  maxTagCount: {
    type: Number,
  },
  placeholder: {
    type: String,
    default: "请选择负责人",
  },
});

const emit = defineEmits(["update:value", "change"]);

const treeData = ref([]);

const fetchTreeData = async () => {
  const { data } = await getDepartmentList();
  const list = convertTreeToList(data[0]);
  const promiseList = list.map((item) =>
    getUsersByDepartment({
      pageNum: 1,
      pageSize: 1000,
      departmentId: item.id,
    })
  );
  const resList = await Promise.all(promiseList);
  console.log(resList);
  treeData.value = resList.map((res, index) => {
    return {
      ...list[index],
      children: res.records,
    };
  });
  console.log(treeData.value);
};

// const fetchDepartments = async () => {
//   const { data } = await getDepartmentList();
//   const list = convertTreeToList(data[0]);
//   treeData.value = list.map((item) => ({
//     ...item,
//     children: [],
//     isLeaf: false,
//   }));
//   // .map((item) => {
//   //   return {
//   //     title: item.name,
//   //     value: item.id,
//   //     key: item.id,
//   //     id: item.id,
//   //     children: [],
//   //     isLeaf: false,
//   //   };
//   // });
// };

const onLoadData = async (treeNode) => {
  console.log(treeNode);
  try {
    console.log(treeNode);
    if (!treeNode.length) {
      return;
    }
    const res = await getUsersByDepartment({
      pageNum: 1,
      pageSize: 1000,
      departmentId: treeNode[treeNode.length - 1],
    });
    console.log(res);
    console.log(treeData.value);
    treeData.value.flat().forEach((item) => {
      if (item.id == treeNode[treeNode.length - 1]) {
        item.children = res.records.map((item) => {
          return {
            ...item,
            children: [],
            isLeaf: true,
          };
        });
        // const children = res.records.map((item) => {
        //   return {
        //     ...item,
        //     children: [],
        //     isLeaf: true,
        //   };
        // });
        // item.children = [...item.children, ...children];
      }
    });
    console.log(treeData.value);
    console.log(unref(treeData.value).flat());
  } catch (error) {
    console.error(error);
  }
};

const handleChange = (value, label, extra) => {
  console.log(value, label, extra);
  emit("update:value", value);
  emit("change", {
    value,
    label,
    extra,
  });
};

onMounted(() => {
  fetchTreeData();
});
</script>
