<script setup>
import { watch, ref } from "vue";

import { RouterView, useRoute } from "vue-router";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

// import { getUserInfo } from "@/api/auth";

import { useMenuStore } from "@/stores/menus";

dayjs.locale("zh-cn");

const route = useRoute();

const menuStore = useMenuStore();

const timestamp = ref(Date.now());

const init = () => {
  // fetchUserInfo();
  fetchPermissions();
};

// const fetchUserInfo = async () => {
//   const res = await getUserInfo();
//   console.log(res);
// };

const fetchPermissions = async () => {
  menuStore.fetchPermissions();
};

watch(
  () => route.path,
  () => {
    // routePath.value = route.path;
    timestamp.value = Date.now();
    init();
  },
  {
    // immediate: true,
  }
);
</script>

<template>
  <a-config-provider
    :autoInsertSpaceInButton="false"
    :locale="zhCN"
    :theme="{
      token: {
        colorPrimary: '#a5cd50',
      },
    }"
  >
    <RouterView />
  </a-config-provider>
</template>
