import { defineStore } from "pinia";

import { getUserPermissions } from "@/api/auth";

import Menus from "@/utils/menus";

function flattenTree(treeArray) {
  const flatArray = [];

  function flatten(node) {
    flatArray.push(node);
    if (node.children && node.children.length > 0) {
      node.children.forEach(flatten);
    }
  }

  treeArray.forEach(flatten);

  return flatArray;
}

function findNodeByURL(menus, targetURL) {
  let foundNode = null;
  let nodeChain = [];

  function traverse(nodes, parentChain = []) {
    for (let node of nodes) {
      const currentChain = [...parentChain, node];
      if (targetURL.indexOf("%26feedbackId%3D") > -1) {
        targetURL = targetURL.split("%26feedbackId%3D")[0];
      }

      if (node.url === targetURL) {
        foundNode = node;
        nodeChain = currentChain;
        break;
      } else if (node.children && node.children.length > 0) {
        traverse(node.children, currentChain);
      }
    }
  }

  traverse(menus);
  return { foundNode, nodeChain };
}

// const pageCodeUrlList = flattenTree(Menus)
//   .filter((item) => !!item.code)
//   .map((item) => ({
//     code: item.code,
//     url: item.url,
//   }));

export const useMenuStore = defineStore("menus", {
  persist: {
    storage: sessionStorage,
  },
  state: () => ({
    headerMenus: [],
    sideMenus: [],
    treeDataSource: [],
    activeHeaderMenu: {},
    activeSideMenu: null,
  }),
  getters: {
    flatDataSource: (state) => {
      return flattenTree(state.treeDataSource);
    },
    permissions: (state) => {
      return [
        ...state.flatDataSource.map((item) => item.code),
        // "DUTY-TYPE-VC-MLO",
        // "DUTY-TYPE-MLO",
        // "DUTY-TYPE-VC",
        // "DUTY-TYPE-MICROBIOLOGY",
      ];
    },
    hasPermissions: (state) => {
      return (code) => {
        if (Array.isArray(code)) {
          return code.some((item) => state.permissions.includes(item));
        }
        return state.permissions.includes(code);
      };
    },
  },
  actions: {
    async fetchPermissions() {
      const _this = this;
      return new Promise((resolve, reject) => {
        getUserPermissions()
          .then((res) => {
            _this.treeDataSource = res.data;
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      });
    },
    checkPageAvaliable(route) {
      console.log(route);
      if (route.name == "iframe") {
        return true;
      }
      if (route.fullPath.indexOf("/feedback-detail/") > -1) {
        return true;
      }
      const code = route.meta.code;
      if (!code) {
        return false;
      }
      const avaliable = this.permissions.includes(code);
      return avaliable;
    },
    generateBpSideMenus(to) {
      const { nodeChain } = findNodeByURL(Menus, to.fullPath);
      this.activeSideMenu = nodeChain[nodeChain.length - 1];
      this.activeHeaderMenu = nodeChain[1];
      this.headerMenus = nodeChain[0].children.map((item) => ({
        ...item,
        children: item.children
          ? item.children.filter((child) => {
              if (child.children) {
                // return this.permissions.includes(child.code);
                return child.children.some((child) => {
                  return this.permissions.includes(child.code);
                });
              } else {
                return this.permissions.includes(child.code);
              }
            })
          : null,
      }));
      let sideMenus = [];
      nodeChain[1].children.forEach((item) => {
        if (Array.isArray(item.children)) {
          const authedChildren = item.children.filter((child) => {
            return this.permissions.includes(child.code);
          });
          if (authedChildren.length) {
            sideMenus.push({
              ...item,
              children: authedChildren,
            });
          }
        } else {
          if (this.permissions.includes(item.code)) {
            sideMenus.push(item);
          }
        }
      });
      this.sideMenus = sideMenus;
      // this.sideMenus = nodeChain[1].children.filter((item) => {
      //   if (Array.isArray(item.children)) {
      //     const authedChildren = item.children.filter((child) => {
      //       return this.permissions.includes(child.code);
      //     });
      //     return authedChildren.length;
      //   } else {
      //     return this.permissions.includes(item.code);
      //   }
      // });
      // console.log(this.activeHeaderMenu);
      // console.log(to);
      // const childMenus = this.activeHeaderMenu.children.find((item) => {
      //   return item.children.find((child) => {
      //     return child.url == to.path;
      //   });
      // });
      // console.log(childMenus);
      // this.sideMenus = this.activeHeaderMenu.children || [];
    },
    generateSideMenus(path, query) {
      const firstLevelMenus = Menus.find(
        (item) => item.url && path.startsWith(item.url)
      );
      const headerMenus = firstLevelMenus.children.map((item) => ({
        ...item,
        children: item.children
          ? item.children.filter((item) => {
              if (item.children) {
                // return this.permissions.includes(item.code);
                return item.children.some((child) => {
                  return this.permissions.includes(child.code);
                });
              } else {
                return this.permissions.includes(item.code);
              }
            })
          : null,
      }));
      let secondLevelMenus = [];
      let sideMenus = [];
      if (path.startsWith("/index/feedback/QA/feedback-detail")) {
        // console.log(path);
        let type = query.type;
        if (type == "5S") {
          type = "6S";
        } else if (type == "Employee Experience") {
          type = "Employee_Experience";
        }
        secondLevelMenus = headerMenus.find((item) => item.label == type) || {};
        this.activeHeaderMenu = secondLevelMenus;
        console.log("=============================");
        console.log(this.activeHeaderMenu);
        console.log("=============================");
      } else {
        secondLevelMenus = headerMenus.find((item) => {
          return item.url && path.startsWith(item.url);
        });
      }
      sideMenus = secondLevelMenus.children.filter((item) =>
        this.permissions.includes(item.code)
      );
      this.headerMenus = headerMenus;
      this.sideMenus = sideMenus;
    },
  },
});
