import { defineStore } from "pinia";

import { onLogin, onLogout, getUserInfo } from "../api/auth";
import { setToken, removeToken } from "@/utils/token";

export const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    userInfo: null,
    isSystemAdmin: false,
  }),
  persist: {
    storage: sessionStorage,
  },
  actions: {
    setToken(value) {
      this.token = value;
    },
    setUser(value) {
      this.userInfo = value;
    },
    async fetchUserInfo() {
      const res = await getUserInfo();
      console.log(res);
      this.userInfo = {
        userOrg: res.data,
      };
      this.isSystemAdmin = res.data.roles.some(
        (item) => item.name == "系统管理员"
      );
    },
    async login(params) {
      return new Promise((resolve, reject) => {
        onLogin(params)
          .then((res) => {
            console.log(res);
            const { loginUser, accessToken } = res.data;
            this.setToken(accessToken);
            this.setUser(loginUser);
            // Cookies.set("token", accessToken);
            setToken(accessToken);
            // Cookies.set("token", accessToken, {
            //   domain: "lab.yyaotec.com",
            // });
            // console.log(
            //   "-------------",
            //   Cookies.get("token", {
            //     domain: "lab.yyaotec.com",
            //   })
            // );
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async logout() {
      return new Promise((resolve) => {
        onLogout()
          .then(() => {
            this.setToken();
            this.setUser();
            resolve();
            localStorage.removeItem("token");
            removeToken();
          })
          .catch(() => {});
      });
    },
  },
});
