import request from "@/api/index";

export function onLogin(data) {
  return request({
    url: "/shipu/login",
    method: "post",
    data,
  });
}

export function onLogout(data) {
  return request({
    url: "/api/labbase/v1/auth/secure/logout",
    method: "post",
    data,
  });
}

/**
 * 获取用户权限
 *
 * @export
 * @return {*}
 */
export function getUserPermissions() {
  return request({
    url: "/api/base/secure/product/permission/tree",
    method: "get",
  });
}

export function getUserInfo() {
  return request({
    url: "/api/base/secure/user/org/one/ext",
    method: "get",
  });
}
