<template>
  <div class="page-title">
    <div class="title">{{ pageTitle }}</div>
    <div class="extra">
      <slot name="extra"></slot>
    </div>
  </div>
  <div
    class="card-box"
    :class="{
      transparent,
    }"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { useRoute } from "vue-router";

defineProps({
  transparent: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

const pageTitle = ref();

console.log(route.path);

watch(
  () => route.path,
  () => {
    pageTitle.value = route.meta?.title;
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.page-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .title {
    color: #432a74;
    font-family: PingFang SC;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.167px;
  }
  .extra {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
.card-box {
  height: 96%;
  border-radius: 6px;
  border: 1px solid #f3f3f7;
  background: #fff;
  box-sizing: border-box;
  overflow-y: auto;
  &.transparent {
    background: transparent;
    border: 0;
  }
}
</style>
