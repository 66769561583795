import request from "@/api/index";

export function getDepartmentList(params) {
  return request({
    url: "/api/base/secure/org/department/tree",
    method: "get",
    params,
  });
}

export function getUsersByDepartment(params) {
  return request({
    url: "/api/labbase/v1/secure/account/page",
    method: "get",
    params,
  });
}

/**
 * 获取侧边栏消息列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export function getMessageData(params) {
  return request({
    url: "/api/labbase/v1/secure/pending/count",
    method: "get",
    params,
    headers: {
      "Accept-Language": "zh",
    },
  });
}

export function getMessageList(params) {
  return request({
    url: "/api/labbase/v1/secure/message/page",
    method: "get",
    params,
    headers: {
      "Accept-Language": "zh",
    },
  });
}

export function batchRead(data) {
  return request({
    url: "/api/labbase/v1/secure/message/read/batch",
    method: "post",
    data,
    headers: {
      "Accept-Language": "zh",
    },
  });
}

export function readMessage(id) {
  return request({
    url: `/api/labbase/v1/secure/message/read/${id}`,
    method: "post",
    headers: {
      "Accept-Language": "zh",
    },
  });
}
