import "./assets/main.css";
import "./assets/antd/index.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import piniaPluginPersistedState from "pinia-plugin-persistedstate";

import PagePanel from "./components/layout/PagePanel.vue";
import YTable from "./components/common/YTable.vue";
import YButton from "./components/common/YButton.vue";
import YInput from "./components/common/YInput.vue";
import YInputNumber from "./components/common/YInputNumber.vue";
import YSpace from "./components/common/YSpace.vue";
import YDrawer from "./components/common/YDrawer.vue";
import DrawerSubmitFooter from "./components/common/DrawerSubmitFooter.vue";
import YLine from "./components/common/YLine.vue";
import YTreeSelectUsers from "./components/common/YTreeSelectUsers.vue";
import YTreeSelectDepartment from "./components/common/YTreeSelectDepartment.vue";
import DutyTypeSelect from "./components/common/duty-type-select.vue";
import DutyTypeSelectSpare from "./components/common/duty-type-select-spare.vue";

import hasPermi from "@/directive/hasPermi";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

app.component("PagePanel", PagePanel);
app.component("YTable", YTable);
app.component("YButton", YButton);
app.component("YInput", YInput);
app.component("YInputNumber", YInputNumber);
app.component("YSpace", YSpace);
app.component("YDrawer", YDrawer);
app.component("DrawerSubmitFooter", DrawerSubmitFooter);
app.component("YLine", YLine);
app.component("YTreeSelectUsers", YTreeSelectUsers);
app.component("YTreeSelectDepartment", YTreeSelectDepartment);
app.component("DutyTypeSelect", DutyTypeSelect);
app.component("DutyTypeSelectSpare", DutyTypeSelectSpare);

app.component("VueDatePicker", VueDatePicker);

app.directive("hasPermi", hasPermi);

app.use(pinia);
app.use(router);
app.use(Antd);

app.mount("#app");
