import request from "@/api/index";

export function addLabGroup(data) {
  return request({
    url: "/new/duty-group/add",
    method: "post",
    data,
  });
}

export function getLabGroupList(data) {
  return request({
    url: "/new/duty-group/page",
    method: "post",
    data,
  });
}

export function onDelete(data) {
  return request({
    url: "/new/duty-group/removes",
    method: "post",
    data,
  });
}

export function getLabGroupDetail(id) {
  return request({
    url: `/new/duty-group/${id}`,
    method: "get",
  });
}

export function getAllGroup(data) {
  return request({
    url: "/new/duty-group/list",
    method: "post",
    data,
  });
}
