<template>
  <a-list
    :loading="loading"
    itemLayout="horizontal"
    :dataSource="list"
    style="max-height: 500px"
  >
    <template #loadMore>
      <div
        :style="{
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px',
        }"
        v-if="!loading && list.length && total > list.length"
      >
        <a-button @click="onLoadMore">加载更多</a-button>
      </div>
    </template>
    <template #renderItem="{ item }">
      <a-list-item>
        <div
          class="flex message-item"
          :class="{
            opacity: item.read,
          }"
          @click="handleRead(item)"
        >
          <div
            class="img-box"
            :class="{
              unread: !item.read,
            }"
          >
            <img
              src="@/assets/images/bell.png"
              style="width: 32px; height: 32px"
            />
          </div>
          <div class="info" style="width: 60%">
            <div class="text">{{ item.content }}</div>
            <div class="time">{{ formatTime(item.createdDate) }}</div>
          </div>
          <div
            class="view"
            v-if="viewButtonVisible(item)"
            @click.stop="onView(item)"
          >
            去查看 >
          </div>
        </div>
      </a-list-item>
    </template>
  </a-list>
</template>

<script setup>
import { onMounted, ref, defineProps, nextTick, onUnmounted } from "vue";
import { formatTime } from "@/utils/utils";

import { useRouter } from "vue-router";

import mitt from "@/utils/mitt";

import { getMessageList, readMessage } from "@/api/user";

const router = useRouter();

const props = defineProps({
  module: {
    type: String,
  },
  type: {
    type: String,
  },
  isRead: {
    type: String,
  },
});

// const initLoading = ref(true);
const loading = ref(false);
const data = ref([]);
const list = ref([]);
const searchForm = ref({
  module: props.module,
  type: props.type,
  isRead: props.isRead,
  pageNum: 1,
  pageSize: 20,
});
const total = ref(0);

const handleRead = async (item) => {
  if (item.read) {
    return;
  }
  await readMessage(item.id);
  mitt.emit("refreshMessageCount");
  const tempPageNum = searchForm.value.pageNum;
  searchForm.value.pageSize = list.value.length;
  searchForm.value.pageNum = 1;
  list.value = [];
  fetchData();
  nextTick(() => {
    searchForm.value.pageNum = tempPageNum;
    searchForm.value.pageSize = 20;
  });
};

const viewButtonVisible = (item) => {
  if (!item.entityUrl) {
    return false;
  }
  const url = JSON.parse(item.entityUrl);
  return url?.WEB?.url;
};

const fetchData = async () => {
  loading.value = true;
  const readStatus = {
    all: null,
    read: true,
    unread: false,
  };
  const res = await getMessageList({
    ...searchForm.value,
    isRead: readStatus[props.isRead],
  });
  loading.value = false;
  data.value = res.records;
  list.value = list.value.concat(data.value);
  total.value = res.total;
};

const onLoadMore = () => {
  searchForm.value.pageNum++;
  fetchData();
};

const onView = (item) => {
  const entityUrl = JSON.parse(item.entityUrl);
  const url = entityUrl.WEB.url;
  // window.open(url.WEB);
  if (["INVENTORY", "ASSET"].includes(item.applicationCode)) {
    window.open(url);
  } else {
    // window.location.href = url;
    router.push(url.split(".com")[1]);
  }
};

onMounted(() => {
  console.log("~~~~~~~~~~~~~~~~~~");
  console.log(props);
  console.log("~~~~~~~~~~~~~~~~~~");
  fetchData();
  mitt.on("refreshMessageList", () => {
    searchForm.value.pageNum = 1;
    list.value = [];
    total.value = 0;
    fetchData();
  });
});

onUnmounted(() => {
  mitt.off("refreshMessageList");
});
</script>

<style lang="scss" scoped>
::v-deep {
  .message-item {
    cursor: pointer;
    width: 100%;
    position: relative;
    padding: 15px 20px;
    padding-right: 55px;
    &.opacity {
      opacity: 0.5;
    }
    &:hover {
      background: #eef9ff;
      .view {
        opacity: 1;
      }
    }
    .img-box {
      position: relative;
    }
    .unread {
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #e16262;
        display: block;
        content: "";
      }
    }
    .info {
      margin: 0 20px;
      flex: 1;
    }
    .view {
      position: absolute;
      top: 0px;
      bottom: 0;
      right: 10px;
      display: flex;
      align-items: center;
      opacity: 0;
      color: rgb(0, 164, 245);
      cursor: pointer;
    }
  }
}

::v-deep {
  .ant-list-items {
    max-height: 700px;
    overflow-y: auto;
    .ant-list-item {
      padding: 0;
    }
  }
}
</style>
