<template>
  <a-drawer :width="956" title="消息通知" :open="true">
    <a-tabs
      :tab-position="'left'"
      v-model:activeKey="activeKey"
      style="height: 100%"
    >
      <a-tab-pane v-for="(item, index) in dataSource.messages" :key="index">
        <template #tab>
          <div class="tab-title">
            <img
              :src="item.highlightIcon"
              v-if="activeKey == index"
              style="width: 24px; height: 24px"
            />
            <img
              :src="item.normalIcon"
              v-else
              style="width: 24px; height: 24px"
            />
            <span
              class="type-name"
              :class="{
                active: activeKey == index,
              }"
              >{{ item.typeName }}</span
            >
            <span class="type-count" v-if="item.count > 0">{{
              item.count
            }}</span>
          </div>
        </template>
        <a-tabs
          :tab-position="'left'"
          v-model:activeKey="innerActiveKey"
          v-if="item.type && activeKey == index"
        >
          <a-tab-pane
            v-for="(innerTab, idx) in item.children"
            :key="`${index}-${idx}`"
          >
            <template #tab>
              <div class="inner-tab-title">
                <img :src="innerTab.icon" class="inner-tab-icon" />
                <div class="inner-type-name">
                  <span class="name">{{ innerTab.typeName }}</span>
                  <span v-if="innerTab.count" class="count"
                    >({{ innerTab.count }})</span
                  >
                </div>
              </div>
            </template>
            <div class="message-box">
              <div class="flex">
                <div class="tabs" style="flex: 1">
                  <div
                    :class="{
                      active: topActiveKey == 'all',
                    }"
                    class="tab all"
                    @click="topActiveKey = 'all'"
                  >
                    全部
                  </div>
                  <div
                    :class="{
                      active: topActiveKey == 'unread',
                    }"
                    class="tab unread"
                    @click="topActiveKey = 'unread'"
                  >
                    未读
                  </div>
                  <div
                    :class="{
                      active: topActiveKey == 'read',
                    }"
                    class="tab read"
                    @click="topActiveKey = 'read'"
                  >
                    已读
                  </div>
                </div>
                <div class="flex" style="gap: 20px">
                  <div class="btn btn-refresh" @click="handleRefresh">刷新</div>
                  <div class="btn btn-read-all" @click="handleBatchRead">
                    一键已读
                  </div>
                </div>
              </div>
              <MessageList
                :module="item.type"
                :type="innerTab.type"
                :isRead="topActiveKey"
                v-if="isInnerTabActive(innerTab.type)"
                :key="`${activeKey}#${innerActiveKey}#${topActiveKey}`"
              />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";

import mitt from "@/utils/mitt";

import MessageList from "./message-list.vue";

import { batchRead } from "@/api/user";

const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
});

console.log(props.dataSource.messages);

const activeKey = ref(0);
const innerActiveKey = ref("0-0");
const topActiveKey = ref("all");
const messageList = ref([]);

const handleBatchRead = async () => {
  const [outIndex, innerIndex] = innerActiveKey.value.split("-");
  const item = props.dataSource.messages[outIndex];
  const inner = item.children[innerIndex];
  const params = {
    isRead: true,
    module: item.type,
    type: inner.type,
  };
  await batchRead(params);
  handleRefresh();
  mitt.emit("refreshMessageCount");
};

const isInnerTabActive = (type) => {
  const [outIndex, innerIndex] = innerActiveKey.value.split("-");
  const item = props.dataSource.messages[outIndex];
  return item.children[innerIndex].type == type;
};

console.log(messageList.value);

const handleRefresh = () => {
  mitt.emit("refreshMessageList");
};

watch(
  () => activeKey.value,
  () => {
    innerActiveKey.value = `${activeKey.value}-0`;
    topActiveKey.value = "all";
  }
);

watch(
  () => innerActiveKey.value,
  () => {
    topActiveKey.value = "all";
  }
);
</script>

<style lang="scss" scoped>
::v-deep {
  .tab-title {
    display: flex;
    align-items: center;
  }
  .type-name {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #344563;
    margin-left: 6px;
    transition: all 0.3s;
    &.active {
      color: var(--primary-color) !important;
    }
  }
  .type-count {
    background: #e16262;
    border-radius: 6px;
    border: 1px solid #fff;
    color: #fff;
    padding: 1px 4px;
    margin-left: 30px;
    position: relative;
    font-weight: normal !important;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 2px;
      left: -30px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #e16262;
    }
  }
}

::v-deep {
  .inner-tab-title {
    display: flex;
    align-items: center;
    .inner-tab-icon {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }
    .inner-type-name {
      .name {
      }
      .count {
        position: relative;
        &::after {
          display: block;
          content: "";
          position: absolute;
          top: 0px;
          right: -3px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #e16262;
        }
      }
    }
  }
}

::v-deep {
  .message-box {
    border-bottom: 1px solid #f0f0f0;
    .tabs {
      display: flex;
      align-items: center;
      .tab {
        padding: 12px 0;
        cursor: pointer;
        position: relative;
        &.active {
          color: var(--primary-color) !important;
          &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #a5cd50 !important;
          }
        }
        &:hover {
          color: rgb(0, 164, 245);
        }
        & + .tab {
          margin-left: 32px;
        }
      }
    }
  }
  .btn {
    cursor: pointer;
    color: rgb(0, 164, 245);
  }
}

::v-deep {
  .ant-tabs-tab {
    &:hover {
      .type-name {
        color: #29bfff;
      }
    }
  }
  .ant-tabs:not(.ant-tabs-left) {
    .ant-tabs-nav {
      height: auto;
      padding: 0;
    }
    .ant-tabs-ink-bar {
      background: var(--primary-color);
      height: 2px !important;
    }
    // .ant-tabs-tab-btn {
    //   color: #1b1b25;
    //   font-family: PingFang SC;
    //   font-size: 14px;
    //   font-style: normal;
    //   font-weight: 500;
    // }
    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--primary-color);
        font-weight: normal;
      }
    }
    // & > .ant-tabs-tab + .ant-tabs-tab {
    //   margin: 0 0 0 60px;
    // }
  }
}
</style>
