<template>
  <div class="right-bar">
    <div class="icon-box icon-message" @click="showMessage">
      <div class="message-count" v-if="messageCount">
        {{ messageCount }}
      </div>
      <img src="../../../assets/images/icon-message.png" />
    </div>
    <message-drawer
      v-if="drawerVisible"
      v-model:visible="drawerVisible"
      :data-source="dataSource"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import mitt from "@/utils/mitt";

import { getMessageData } from "@/api/user";

import MessageDrawer from "./message-drawer.vue";

let timer = null;

const drawerVisible = ref(false);
const dataSource = ref({});
const messageCount = ref(0);

// const openDocumentSite = () => {
//   window.open("");
// };

const showMessage = () => {
  drawerVisible.value = true;
  console.log(drawerVisible.value);
};

const fetchMessage = async () => {
  const res = await getMessageData();
  dataSource.value = {
    ...res,
    messages: res.messages.map((item) => ({
      ...item,
      normalIcon: JSON.parse(item.icon)?.normal,
      highlightIcon: JSON.parse(item.icon)?.highlight,
    })),
  };
  messageCount.value =
    // (res.countPending || 0)
    // +
    res.countMessage || 0;
};

const refreshMessage = () => {
  fetchMessage();
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
  timer = setInterval(() => {
    fetchMessage();
  }, 1000 * 60);
};

onMounted(() => {
  refreshMessage();
  mitt.on("refreshMessageCount", () => {
    refreshMessage();
  });
});
</script>

<style lang="scss" scoped>
.right-bar {
  flex-shrink: 0;
  width: 60px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding-top: 48px;
  .icon-box {
    cursor: pointer;
    position: relative;
    .message-count {
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px; /* 125% */
      letter-spacing: 0.167px;
      position: absolute;
      left: -27px;
      top: -11px;
      border-radius: 10px 10px 0px 10px;
      background: #eb3c96;
      padding: 3px 8px;
    }
  }
}
</style>
