// import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/home/HomeView.vue";

import { useMenuStore } from "@/stores/menus";
import { useUserStore } from "@/stores/user";
import { getToken } from "@/utils/token";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/LoginView.vue"),
    },
    {
      path: "/no-permission",
      name: "no-permission",
      component: () => import("../views/NoPermissiuon.vue"),
    },
    {
      path: "/index",
      name: "index",
      component: () => import("../views/index/IndexView.vue"),
      children: [
        {
          path: "EAG",
          name: "EAG",
          children: [
            {
              path: "appreciate",
              name: "employee-appreciation",
              meta: {
                title: "员工感谢",
              },
              children: [
                {
                  path: "review",
                  name: "appreciate-review",
                  component: () =>
                    import(
                      "../views/EAG/employee-appreciation/check-list/CheckList.vue"
                    ),
                  meta: {
                    title: "感谢公开审核",
                    code: "EAG-EA-AA",
                  },
                },
                {
                  path: "record",
                  name: "appreciate-record",
                  component: () =>
                    import(
                      "../views/EAG/employee-appreciation/history-list/HistoryList.vue"
                    ),
                  meta: {
                    title: "历史感谢",
                    code: "EAG-EA-AR",
                  },
                },
              ],
            },
            {
              path: "exercise-check-in",
              name: "exercise-check-in",
              children: [
                {
                  path: "review",
                  name: "check-in-review",
                  component: () =>
                    import(
                      "../views/EAG/exercise-check-in/review/review-list.vue"
                    ),
                  meta: {
                    title: "打卡审核",
                    code: "EAG-FC-CA",
                  },
                },
                {
                  path: "record",
                  name: "check-in-record",
                  component: () =>
                    import(
                      "../views/EAG/exercise-check-in/record/record-list.vue"
                    ),
                  meta: {
                    title: "打卡记录",
                    code: "EAG-FC-CR",
                  },
                },
                {
                  path: "award",
                  name: "check-in-award",
                  component: () =>
                    import(
                      "../views/EAG/exercise-check-in/award/award-list.vue"
                    ),
                  meta: {
                    title: "打卡奖励",
                    code: "EAG-FC-CRW",
                  },
                },
                {
                  path: "config",
                  name: "check-in-config",
                  component: () =>
                    import(
                      "../views/EAG/exercise-check-in/config/config-page.vue"
                    ),
                  meta: {
                    title: "打卡配置",
                    code: "EAG-FC-CC",
                  },
                },
              ],
            },
            {
              path: "qa",
              name: "qa",
              meta: {
                title: "调查问卷",
                icon: "question-list",
              },
              children: [
                {
                  path: "question-list",
                  name: "question-list",
                  component: () =>
                    import("../views/EAG/qa/question-list/question-list.vue"),
                  meta: {
                    code: "EAG-SVY-SM",
                  },
                },
                {
                  path: "question-list/:questionId",
                  name: "question-detail",
                  component: () =>
                    import("../views/EAG/qa/qa-list/qa-list.vue"),
                  meta: {
                    code: "EAG-SVY-SM",
                  },
                },
              ],
            },
            {
              path: "stat",
              name: "stat",
              meta: {
                title: "大屏配置",
                icon: "question-list",
              },
              children: [
                {
                  path: "config",
                  name: "config",
                  component: () => import("../views/EAG/stat/stat-config.vue"),
                  meta: {
                    code: "EAG-SVY-SM-STAT",
                    // code: "EAG-SVY-SM",
                  },
                },
              ],
            },
          ],
        },
        {
          path: "facility",
          name: "facility",
          children: [
            {
              path: "energy",
              name: "energy",
              children: [
                {
                  path: "statistics",
                  name: "energy-statistics",
                  component: () =>
                    import(
                      "@/views/facility/energy-stattistics/energy-chart/EnergyChart.vue"
                    ),
                  meta: {
                    title: "能耗统计",
                    code: "FACILITY-EG-ECS",
                    // icon: 'energy-statistics'
                  },
                },
                {
                  path: "config",
                  name: "energy-config",
                  component: () =>
                    import("@/views/facility/energy-config/config-page.vue"),
                  meta: {
                    title: "能源配置",
                    code: "FACILITY-EG-EC",
                    // icon: 'energy-statistics'
                  },
                },
              ],
            },
          ],
        },
        {
          path: "admin",
          name: "admin",
          children: [
            {
              path: "order-food",
              name: "order-food",
              children: [
                {
                  path: "initiate",
                  name: "order-food-initiate",
                  component: () =>
                    import(
                      "../views/admin/order-food/order-in-progress/order-in-progress.vue"
                    ),
                  meta: {
                    title: "发起订餐",
                    code: "ADMIN-ODM-INIT-IO",
                  },
                },
                {
                  path: "history",
                  name: "order-food-history",
                  component: () =>
                    import(
                      "../views/admin/order-food/order-history/order-history.vue"
                    ),
                  meta: {
                    title: "历史订餐",
                    code: "ADMIN-ODM-HIST-REC-CHILD",
                  },
                },
                {
                  path: "detail/:orderId",
                  name: "progress-order-detail",
                  component: () =>
                    import("../views/admin/order-food/detail/order-detail.vue"),
                  meta: {
                    title: "订餐详情",
                    icon: "employee-appreciation",
                    code: "ADMIN-ODM-INIT-DET",
                  },
                },
                {
                  path: "history/detail/:orderId",
                  name: "history-order-detail",
                  component: () =>
                    import("../views/admin/order-food/detail/order-detail.vue"),
                  meta: {
                    title: "订餐详情",
                    icon: "employee-appreciation",
                    code: "ADMIN-ODM-HIST-DET",
                  },
                },
              ],
            },
            {
              path: "it",
              name: "it",
              children: [
                {
                  path: "device-list",
                  name: "it-device-list",
                  component: () =>
                    import("@/views/admin/it/device/device-list.vue"),
                  meta: {
                    title: "IT备品备件",
                    code: "ADMIN-ITM-CTMITQ",
                  },
                },
              ],
            },
          ],
        },
        {
          path: "feedback",
          name: "feedback",
          children: [
            {
              path: "QA",
              name: "QA",
              children: [
                {
                  path: "feedback-query",
                  redirect: "/index/feedback/QA/EHS",
                },
                {
                  path: "EHS",
                  name: "EHS",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/FeedbackQuery.vue"
                    ),
                  meta: {
                    title: "反馈查询",
                    icon: "employee-appreciation",
                    code: "EAG-EHS-FH",
                  },
                },
                {
                  path: "QA",
                  name: "QA",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/FeedbackQuery.vue"
                    ),
                  meta: {
                    title: "反馈查询",
                    icon: "employee-appreciation",
                    code: "EAG-QA-FH",
                  },
                },
                {
                  path: "5S",
                  name: "5S",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/FeedbackQuery.vue"
                    ),
                  meta: {
                    title: "反馈查询",
                    icon: "employee-appreciation",
                    code: "EAG-5S-FH",
                  },
                },
                {
                  path: "Employee_Experience",
                  name: "Employee_Experience",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/FeedbackQuery.vue"
                    ),
                  meta: {
                    title: "反馈查询",
                    icon: "employee-appreciation",
                    code: "EAG-EMP-FH",
                  },
                },
                {
                  path: "Others",
                  name: "Others",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/FeedbackQuery.vue"
                    ),
                  meta: {
                    title: "反馈查询",
                    icon: "employee-appreciation",
                    code: "EAG-OTH-FH",
                  },
                },
                {
                  path: "Sustainability",
                  name: "Sustainability",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/FeedbackQuery.vue"
                    ),
                  meta: {
                    title: "反馈查询",
                    icon: "employee-appreciation",
                    code: "EAG-SBT-FH",
                  },
                },
                {
                  path: "feedback-detail/:feedbackId",
                  name: "feedback-detail-ehs",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/components/feedback-report/FeedbackDetail.vue"
                    ),
                  meta: {
                    title: "反馈详情",
                    icon: "employee-appreciation",
                    code: "EAG-EHS-FH",
                  },
                },
                {
                  path: "feedback-detail/:feedbackId",
                  name: "feedback-detail-qa",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/components/feedback-report/FeedbackDetail.vue"
                    ),
                  meta: {
                    title: "反馈详情",
                    icon: "employee-appreciation",
                    code: "EAG-QA-FH",
                  },
                },
                {
                  path: "feedback-detail/:feedbackId",
                  name: "feedback-detail-5s",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/components/feedback-report/FeedbackDetail.vue"
                    ),
                  meta: {
                    title: "反馈详情",
                    icon: "employee-appreciation",
                    code: "EAG-5S-FH",
                  },
                },
                {
                  path: "feedback-detail/:feedbackId",
                  name: "feedback-detail-eag",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/components/feedback-report/FeedbackDetail.vue"
                    ),
                  meta: {
                    title: "反馈详情",
                    icon: "employee-appreciation",
                    code: "EAG-EMP-FH",
                  },
                },
                {
                  path: "feedback-detail/:feedbackId",
                  name: "feedback-detail-others",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/components/feedback-report/FeedbackDetail.vue"
                    ),
                  meta: {
                    title: "反馈详情",
                    icon: "employee-appreciation",
                    code: "EAG-OTH-FH",
                  },
                },
                {
                  path: "feedback-detail/:feedbackId",
                  name: "feedback-detail-sus",
                  component: () =>
                    import(
                      "../views/feedback/feedback-query/components/feedback-report/FeedbackDetail.vue"
                    ),
                  meta: {
                    title: "反馈详情",
                    icon: "employee-appreciation",
                    code: "EAG-SBT-FH",
                  },
                },
                {
                  path: "EHS/area-list/EHS",
                  name: "area-list-EHS",
                  component: () =>
                    import("@/views/feedback/area-list/area-list.vue"),
                  meta: {
                    title: "区域管理",
                    // code: "EAG-FBK-AREA",
                    code: "EAG-EHS-AREA",
                    // icon: 'energy-statistics'
                  },
                },
                {
                  path: "QA/area-list/QA",
                  name: "area-list-QA",
                  component: () =>
                    import("@/views/feedback/area-list/qa-owner-config.vue"),
                  meta: {
                    title: "处理人配置",
                    // code: "EAG-FBK-AREA",
                    code: "EAG-QA-AREA",
                    // icon: 'energy-statistics'
                  },
                },
                {
                  path: "5S/area-list/5S",
                  name: "area-list-5S",
                  component: () =>
                    import("@/views/feedback/area-list/area-list.vue"),
                  meta: {
                    title: "区域管理",
                    // code: "EAG-FBK-AREA",
                    code: "EAG-5S-AREA",
                    // icon: 'energy-statistics'
                  },
                },
                {
                  path: "Sustainability/area-list/Sustainability",
                  name: "area-list-Sustainability",
                  component: () =>
                    import(
                      "@/views/feedback/manager-config/manager-config-sustainability.vue"
                    ),
                  meta: {
                    title: "处理人配置",
                    // code: "EAG-FBK-AREA",
                    code: "EAG-SBT-MNG",
                    // icon: 'energy-statistics'
                  },
                },
                {
                  path: "Others/area-list/Others",
                  name: "area-list-Others",
                  component: () =>
                    // import(
                    //   "@/views/feedback/manager-config/manager-config-others.vue"
                    // ),
                    import(
                      "@/views/feedback/manager-config/manager-config-sustainability.vue"
                    ),
                  meta: {
                    title: "处理人配置",
                    // code: "EAG-FBK-AREA",
                    code: "EAG-OTH-MNG",
                    // icon: 'energy-statistics'
                  },
                },
                {
                  path: "Employee_Experience/area-list/Employee_Experience",
                  name: "area-list-Employee_Experience",
                  component: () =>
                    // import(
                    //   "@/views/feedback/manager-config/manager-config-employee.vue"
                    // ),
                    import(
                      "@/views/feedback/manager-config/manager-config-sustainability.vue"
                    ),
                  meta: {
                    title: "处理人配置",
                    code: "EAG-EMP-MNG",
                  },
                },
              ],
            },
          ],
        },
        {
          path: "vc/vp",
          name: "vc/vp",
          children: [
            {
              path: "on-duty",
              name: "on-duty",
              children: [
                {
                  path: "duty",
                  name: "duty",
                  component: () =>
                    import("@/views/VCVP/duty/duty/duty-schedule.vue"),
                  meta: {
                    title: "值日排期",
                    code: "VCVP-DTY-SM",
                    icon: "employee-appreciation",
                  },
                },
                {
                  path: "clean-plan",
                  name: "clean-plan",
                  component: () =>
                    import("@/views/VCVP/duty/clean-plan/clean-plan.vue"),
                  meta: {
                    title: "设备清洁计划",
                    code: "VCVP-DTY-CC",
                    icon: "employee-appreciation",
                  },
                },
                {
                  path: "duty-adjustment-review",
                  name: "duty-adjustment-review",
                  component: () =>
                    import(
                      "../views/VCVP/duty/duty-adjustment-review/DutyAdjustmentReview.vue"
                    ),
                  meta: {
                    title: "值日调整审核",
                    code: "VCVP-DTY-CA",
                    icon: "employee-appreciation",
                  },
                },
                {
                  path: "duty-record",
                  name: "duty-record",
                  component: () =>
                    import("../views/VCVP/duty/duty-record/DutyRecord.vue"),
                  meta: {
                    title: "值日记录",
                    code: "VCVP-DTY-DR",
                    icon: "employee-appreciation",
                  },
                },
                {
                  path: "change-records",
                  name: "change-records",
                  component: () =>
                    import(
                      "@/views/VCVP/duty/duty-status-change-records/duty-status-change-records.vue"
                    ),
                  meta: {
                    title: "清洁状态修改记录",
                    code: "Lab-DTY-ZTXG",
                    icon: "employee-appreciation",
                  },
                },
                {
                  path: "clean-items",
                  name: "clean-items",
                  component: () =>
                    import("@/views/VCVP/duty/clean-items/clean-items.vue"),
                  meta: {
                    title: "清洁项",
                    code: "Lab-DTY-QJXM",
                    icon: "employee-appreciation",
                  },
                },
                {
                  path: "clean-items-type",
                  name: "clean-items-type",
                  component: () =>
                    import(
                      "@/views/VCVP/duty/clean-items-type/clean-items-type.vue"
                    ),
                  meta: {
                    title: "清洁项类型",
                    code: "Lab-DTY-QJLXM",
                    icon: "employee-appreciation",
                  },
                },
              ],
            },
            {
              path: "management-center",
              name: "management-center",
              redirect: "lab-group",
              children: [
                {
                  path: "lab-group",
                  name: "lab-group",
                  component: () =>
                    import(
                      "@/views/VCVP/management-center/lab-group/lab-group.vue"
                    ),
                  meta: {
                    title: "实验组",
                    code: "VCVP-MNG-SYZM",
                  },
                },
                {
                  path: "lab-group/:id",
                  name: "lab-group-detail",
                  component: () =>
                    import(
                      "@/views/VCVP/management-center/lab-group/lab-config.vue"
                    ),
                  meta: {
                    title: "实验组编辑",
                    code: "VCVP-MNG-SYZM",
                  },
                },
                {
                  path: "room-list",
                  name: "room-list",
                  component: () =>
                    import(
                      "@/views/VCVP/management-center/room-list/room-list.vue"
                    ),
                  meta: {
                    title: "房间",
                    code: "VCVP-MNG-ROOMM",
                  },
                },
              ],
            },
            {
              path: "spare",
              name: "spare",
              children: [
                {
                  path: "device-list",
                  name: "spare-device-list",
                  component: () =>
                    import("@/views/VCVP/spare-parts/device/device-list.vue"),
                  meta: {
                    title: "库存查询",
                    code: "LAB-SP-KCCX",
                  },
                },
                {
                  path: "device-list/inventory-check",
                  name: "spare-inventory-check",
                  component: () =>
                    import(
                      "@/views/VCVP/spare-parts/inventory-check/device-list.vue"
                    ),
                  meta: {
                    title: "库存盘点",
                    code: "LAB-SP-KCGL",
                  },
                },
              ],
            },
          ],
        },

        {
          path: "iframe",
          children: [
            {
              path: ":pageUrl",
              name: "iframe",
              component: () => import("../views/iframe/YWebview.vue"),
            },
          ],
        },
      ],
    },
  ],
});

const whiteList = ["/login"];

router.beforeEach((to, from, next) => {
  const token = getToken();
  const menuStore = useMenuStore();
  if (!token) {
    if (whiteList.indexOf(to.path) != -1) {
      next();
    } else {
      console.log(process.env.NODE_ENV == "development");
      if (process.env.NODE_ENV == "deveopment") {
        router.push("/login");
      } else {
        router.push("/login");
        // window.location.href = `${process.env.VUE_APP_BASE_URL}/user/login`;
      }
    }
  } else {
    const userStore = useUserStore();
    userStore.token = token;
    if (to.path == "/login") {
      // window.location.href = `${process.env.VUE_APP_BASE_URL}/user/login`;
      if (process.env.NODE_ENV == "deveopment") {
        router.push("/login");
      } else {
        window.location.href = `${process.env.VUE_APP_BASE_URL}/user/login`;
      }
    } else {
      userStore.fetchUserInfo();
      if (menuStore.treeDataSource.length == 0) {
        menuStore.fetchPermissions().then(() => {
          if (
            ["/", "/home", "/index"].includes(to.path) ||
            to.path.indexOf("/index/iframe") > -1
          ) {
            if (to.path.indexOf("/index/iframe") > -1) {
              menuStore.generateBpSideMenus(to);
            }
            next();
          } else {
            if (menuStore.checkPageAvaliable(to)) {
              menuStore.generateSideMenus(to.path, to.query);
              next({
                path: to.path,
                replace: true,
                query: to.query,
              });
            } else {
              next({
                // path: "/index/no-permission",
                path: "/no-permission",
              });
            }
          }
        });
      } else {
        if (
          ["/", "/home", "/index"].includes(to.path) ||
          to.path.indexOf("/index/iframe") > -1
        ) {
          if (to.path.indexOf("/index/iframe") > -1) {
            menuStore.generateBpSideMenus(to);
          }
          next();
        } else {
          if (menuStore.checkPageAvaliable(to)) {
            menuStore.generateSideMenus(to.path, to.query);
            next();
          } else {
            if (to.path.indexOf("no-permission") > -1) {
              next();
            } else {
              next({
                // path: "/index/no-permission",
                path: "/no-permission",
              });
            }
          }
        }
      }
    }
    // next();
  }
});

export default router;
