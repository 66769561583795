import Cookies from "js-cookie";

const DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;

console.log(DOMAIN);

const setToken = (token) => {
  const params = {};
  if (DOMAIN) {
    params.domain = DOMAIN;
  }
  Cookies.set("token", token, {
    ...params,
  });
};

const removeToken = () => {
  const params = {};
  if (DOMAIN) {
    params.domain = DOMAIN;
  }
  Cookies.remove("token", {
    ...params,
  });
};

const getToken = () => {
  const params = {};
  if (DOMAIN) {
    params.domain = DOMAIN;
  }
  return Cookies.get("token", {
    ...params,
  });
};

export { setToken, getToken, removeToken };
