<template>
  <a-tree-select
    size="large"
    style="width: 100%"
    :placeholder="placeholder"
    :tree-data="treeData"
    :maxTagCount="maxTagCount"
    :treeCheckable="treeCheckable"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :field-names="{
      children: 'children',
      label: 'name',
      value: 'id',
    }"
    @change="handleChange"
    @treeExpand="onLoadData"
  ></a-tree-select>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from "vue";

import { getDepartmentList } from "@/api/user";

// const props =
defineProps({
  treeCheckable: {
    type: Boolean,
    default: false,
  },
  maxTagCount: {
    type: Number,
  },
  placeholder: {
    type: String,
    default: "请选择部门",
  },
});

const emit = defineEmits(["update:value", "change"]);

const treeData = ref([]);

const fetchTreeData = async () => {
  const { data } = await getDepartmentList();
  treeData.value = data;
  console.log(treeData.value);
};

const handleChange = (value, label, extra) => {
  console.log(value, label, extra);
  emit("update:value", value);
  emit("change", {
    value,
    label,
    extra,
  });
};

onMounted(() => {
  fetchTreeData();
});
</script>
